<template>
  <v-container>
    <!-- <MultiMap /> -->
    <VehicleImport />
  </v-container>
</template>

<script>
import axios from 'axios'
// import ClientForm from "./ClientForm.vue";
import VehicleImport from './VehicleImport.vue'
// import ClientDashboard from '../views/Dashboard.vue'
// import MultiMap from './MultiMap.vue'

export default {
  components: {
    // ClientForm,
    // MTLImport,
    // ClientDashboard,
    // MultiMap,
    VehicleImport,
  },
  data() {
    return {
      reportedDamage: '',
      items: ['Programming', 'Design', 'Vue', 'Vuetify'],
      center: { lat: 55.4281113, lng: 24.1197816 },
      markers: [],
      formData: {
        incidentLatitude: '',
        incidentLongitude: '',
        incidentLocation: '',
      },
      selectClients: [],
    }
  },
  methods: {
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        this.success,
        this.error,
        this.options
      )
    },
    success(pos) {
      var crd = pos.coords
      this.formData.incidentLatitude = crd.latitude
      this.formData.incidentLongitude = crd.longitude
      let coords = { lat: crd.latitude, lng: crd.longitude }
      this.reverseGeocode(coords)
      this.setMarker(coords)
      this.center = coords
    },
    error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`)
    },
    reverseGeocode(coords) {
      let { lat, lng } = coords
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA6u3vh8LfDZsXyAQ1-tRU5abixhcxnYWk`
        )
        .then((response) => {
          this.formData.incidentLocation =
            response.data.results[0].formatted_address
        })
    },
    setMarker(coords) {
      let marker = {}
      marker.position = coords
      this.markers = []
      this.markers.push(marker)
    },
    markerMoved(event) {
      let lat = event.lat()
      let lng = event.lng()
      this.formData.incidentLatitude = lat
      this.formData.incidentLongitude = lng
      //TODO: discuss moving this requests to backend, to obfuscate API addresses
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA6u3vh8LfDZsXyAQ1-tRU5abixhcxnYWk`
        )
        .then((response) => {
          this.formData.incidentLocation =
            response.data.results[0].formatted_address
        })
      this.center = { lat, lng }
    },
    searchGeoService() {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.$refs.searchText.value},+CA&key=AIzaSyA6u3vh8LfDZsXyAQ1-tRU5abixhcxnYWk`
        )
        .then((response) => {
          let data = response.data.results
          this.formData.incidentLatitude = data[0].geometry.location.lat
          this.formData.incidentLongitude = data[0].geometry.location.lng
          this.formData.incidentLocation = data[0].formatted_address
          this.setMarker({
            lat: this.formData.incidentLatitude,
            lng: this.formData.incidentLongitude,
          })
          this.center = {
            lat: this.formData.incidentLatitude,
            lng: this.formData.incidentLongitude,
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
