<template>
  <v-container>
    <v-row>
      <v-col align-self="center">
        <v-autocomplete
          v-if="clients"
          :items="selectItems"
          v-model="selectedClient"
          @change="selectClient"
          :label="$t('wizard.selectCompany')"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4 class="title is-2">{{ $t('imports.importVehicles') }}</h4>
        <v-file-input
          accept=".csv"
          v-model="importFile"
          counter
          show-size
          truncate-length="15"
          @change="parseImportFile"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4 class="title is-2">{{ $t('imports.importPreview') }}</h4>
        <v-data-table
          :headers="headersForImport"
          :items="dataForImport"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          :disabled="selectedClient == null || dataForImport.length == 0"
          @click="importVehicles"
          >{{ $t('imports.importPreview') }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      selectedClient: null,
      selectItems: [],
      importFile: null,
      dataForImport: [],
      headersForImport: [
        {
          text: this.$i18n.t('headers.registration'),
          value: 'registration',
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('client/getClients')
  },
  methods: {
    parseImportFile(file) {
      if (file) {
        let papaConfig = {
          complete: this.prepareData,
          delimiter: ';',
          header: true,
          delimitersToGuess: [';', '\t', '|', ','],
          encoding: 'utf8',
        }
        this.$papa.parse(file, papaConfig)
      } else {
        this.dataForImport = []
        this.headersForImport = []
      }
    },
    prepareData(results) {
      this.dataForImport = results.data
    },
    selectClient(value) {
      this.selectedClient = value
    },
    importVehicles() {
      let payload = {
        clientId: this.selectedClient.id,
        vehicles: this.dataForImport,
      }
      this.$store
        .dispatch('vehicle/importVehicles', payload)
        .then((response) => {
          let snackbar = {
            text: `${this.$i18n.t('imports.imported')} ${
              response.data.vehicles.length
            }, ${this.$i18n.t('imports.failedToImport')} ${
              response.data.failed.length
            }.`,
            color: 'success',
          }
          this.$store.dispatch('snackbar/addSnackbar', snackbar, {
            root: true,
          })
        })
      this.selectedClient = null
      this.importFile = null
      this.dataForImport = []
    },
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
    }),
  },
  watch: {
    clients() {
      this.selectItems = this.clients.map((c) => ({
        text: c.name,
        value: c,
      }))
    },
  },
}
</script>

<style lang="scss" scoped></style>
